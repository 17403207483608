<template>
  <section class="actionButtons">
    <div class="actionButtons__contentIcon actionButtons__contentIcon--return" @click="onCancel" v-if="onCancel">
      <iconic name="closeIcon" />
    </div>
    <div class="actionButtons__contentIcon actionButtons__contentIcon--next" @click="onSuccess" v-if="onSuccess">
      <iconic name="success" />
    </div>
  </section>
</template>

<script>
export default {
  props: ["onSuccess", "onCancel"],
};
</script>

<style lang="scss">
.actionButtons {
  @include Flex(row);
  gap: 10px;
  &__contentIcon {
    @include Flex(row);
    height: 27px;
    width: 27px;
    font-size: 15px;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
    &--return {
      font-size: 12px;
      color: $primary-color;
      border-color: $primary-color;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #ffffff;
        background: $primary-color;
      }
    }
    &--next {
      color: #209e0c;
      border-color: #209e0c;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #ffffff;
        background: #209e0c;
      }
    }
  }
}
</style>
